import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { NamedLink } from '../../../../components';

import css from './TopbarDesktop.module.css';

const CartLink = ({ currentUser }) => {
  const currentCart = currentUser?.attributes.profile.publicData.cart || [];
  const count = currentCart.length;
  return (
    <NamedLink className={css.cartLink} name="CartPage">
      <div className={css.cartIcon}>
        <FontAwesomeIcon icon={faCartShopping} />
        {count > 0 ? <span className={css.cartCounter}>{count}</span> : null}
      </div>
    </NamedLink>
  );
};

export default CartLink;
